import * as Yup from 'yup';

import { INewsletterFormValues } from '../models/newsletter-form.model';
import type useTranslations from '../hooks/use-translations';

export const initialNewsletterValues: Omit<INewsletterFormValues, 'newsletter'> = {
    email: '',
    approval1: false,
};

export function getNewsletterFormSchema(t: ReturnType<typeof useTranslations<'NewsletterForm'>>) {
    return Yup.object().shape({
        email: Yup.string().email(`${t.email.error}`).required(`${t.email.required}`),
        approval1: Yup.boolean().oneOf([true], `${t.consent}`),
    });
}
