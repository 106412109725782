import * as Yup from 'yup';

import { TFormFieldsFactory } from '../models/form-field.model';

export const contactForm: TFormFieldsFactory<
    'ContactForm',
    { consentDescription: string | null }
> = ({ t, consentDescription }) => [
    {
        name: 'name',
        type: 'text',
        label: t.name.label,
        initialValue: '',
        schema: Yup.string().required(t.name.error.required),
    },
    {
        name: 'email',
        type: 'email',
        label: t.email.label,
        initialValue: '',
        schema: Yup.string().email(t.email.error.email).required(t.email.error.required),
    },
    {
        name: 'phone',
        type: 'phone',
        label: t.phone.label,
        showOptionalLabel: true,
        initialValue: '',
        schema: Yup.string(),
    },
    {
        name: 'content',
        type: 'textarea',
        label: t.content.label,
        initialValue: '',
        schema: Yup.string().required(t.content.error.required),
    },
    {
        name: 'approval1',
        type: 'checkbox',
        label: consentDescription ?? '',
        initialValue: false,
        schema: Yup.boolean().oneOf([true], t.approval1.error.required),
    },
    {
        name: 'submit',
        type: 'submit',
        label: t.submit.label,
    },
];
