import React from 'react';

import { wrapper, infoTitle, contentWrapper } from './contact-info.module.scss';

import Markdown from '../hoc/markdown';

interface IContactInfoProps {
    title: string;
    content: string;
    className?: string;
}

const ContactInfo = ({ title, content, className = '' }: IContactInfoProps) => {
    return (
        <div className={`${wrapper} ${className}`}>
            <h4 className={infoTitle}>{title}</h4>
            <Markdown className={contentWrapper}>{content}</Markdown>
        </div>
    );
};

export default ContactInfo;
